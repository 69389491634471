import { useState } from "react";
import Styles from "../styles/Nav.module.scss";
import MedicineLogo from "../assets/medicineLogo.png";
import { gotTo } from "../modules/goto";
import { Registration } from "./Registration";
import { SignIn } from "./SignIn";
import { useNavigate } from "react-router-dom";
export const Nav = () => {
  const [showSignIn, setShowSignIn] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const navigate = useNavigate();
  const togglePopUp = (e: string) => {
    if (e === "registration") {
      setShowRegistration(true);
      setShowSignIn(false);
    } else if (e === "signIn") {
      setShowRegistration(false);
      setShowSignIn(true);
    }
  };
  return (
    <>
      <div className={Styles.mainNav}>
        <img src={MedicineLogo} alt="none" className={Styles.medicineLogo} />
        <div className={Styles.nav}>
          <div onClick={() => navigate("/")}>Home</div>
          <div onClick={() => navigate("/pages")}>Pages</div>
          <div className={Styles.signIn} onClick={() => togglePopUp("signIn")}>
            Sign In
          </div>
          <div
            className={Styles.registration}
            onClick={() => togglePopUp("registration")}
          >
            Registration
          </div>
        </div>
        {showRegistration ? (
          <Registration
            close={() => {
              setShowRegistration(false);
            }}
          />
        ) : (
          ""
        )}
        {showSignIn ? (
          <SignIn
            close={() => {
              setShowSignIn(false);
            }}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
