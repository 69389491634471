import Styles from "../styles/Credentials.module.scss";
import { Sites } from "./Sites";
export const SignIn = ({ close }: { close: () => void }) => {
  return (
    <>
      <div className={Styles.popUp}>
        <p className={Styles.title}>Sign In</p>
        <p className={Styles.subtitle}>
          Sign In at any of our websites using the same Username and Password
        </p>
        <span className={Styles.closeButton} onClick={close}>
          x
        </span>
        <Sites />
      </div>
    </>
  );
};
