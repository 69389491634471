import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Logo from "../assets/medicineLogoWhite.png";
import Styles from "../styles/Reset.module.scss";
import { auth } from "../firebase/config";
import { confirmPasswordReset } from "firebase/auth";
import { Sites } from "../components/Sites";
export const Reset = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [query] = useSearchParams();
  const code: any = query.get("oobCode");
  const changePassword = async () => {
    if (password === "" || password.length < 6) {
      setError("Please insert a valid Password");
    } else {
      if (password === confirmPassword) {
        await confirmPasswordReset(auth, code, password);
        setIsSuccess(true);
      } else {
        setError("Passwords did not match");
      }
    }
  };
  const Success = () => {
    return (
      <div>
        <p className={Styles.title}>Password Successfully reset!</p>
        <hr className={Styles.hr} />
        <p className={Styles.title}>Return to:</p>
        <Sites />
      </div>
    );
  };
  return (
    <>
      <div className={Styles.window}></div>
      <div className={Styles.reset}>
        <div className={Styles.logo}>
          <img src={Logo} alt="non photo" />
        </div>
        <div className={Styles.box}>
          {isSuccess ? (
            <Success />
          ) : (
            <div>
              <p className={Styles.title}>You forgot your Password?</p>
              <p>Don't worry.</p>
              <hr className={Styles.hr} />
              <div className={Styles.form}>
                <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
                <input
                  type="text"
                  placeholder="Enter new Password"
                  className={Styles.input}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Confirm Password"
                  className={Styles.input}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div className={Styles.button} onClick={changePassword}>
                  Reset Password
                </div>
              </div>
            </div>
          )}
          <hr className={Styles.hr} />
          <div className={Styles.footer}>
            <p>Thank you and enjoy our website</p>
            <p>Your Authentication Team</p>
          </div>
        </div>
      </div>
    </>
  );
};
