import Styles from "../styles/Sites.module.scss";
import Onco from "../assets/oncoLogo.png";
import Rheum from "../assets/rheumLogo.png";
import Sleep from "../assets/sleepLogo.png";
import { gotTo } from "../modules/goto";
export const Sites = () => {
  return (
    <>
      {" "}
      <div className={Styles.sitesParent}>
        <div
          className={Styles.sites}
          onClick={() => gotTo("https://onco-minds.com/", "distant")}
        >
          <img src={Onco} alt="no Photo" />
        </div>
        <div
          className={Styles.sites}
          onClick={() => gotTo("https://rheum-minds.com/", "distant")}
        >
          <img src={Rheum} alt="no Photo" />
        </div>
        <div
          className={Styles.sites}
          onClick={() => gotTo("https://sleep-minds.com/", "distant")}
        >
          <img src={Sleep} alt="no Photo" />
        </div>
      </div>
    </>
  );
};
