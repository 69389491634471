import { Routes, Route } from "react-router-dom";
import { Main } from "./pages/Main";
import { NotFound } from "./pages/NotFound";
import { Pages } from "./pages/Pages";
import { Reset } from "./pages/Reset";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/reset-password" element={<Reset />} />
        <Route path="/pages" element={<Pages />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
