import { useState } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../firebase/config";
import Styles from "../styles/Credentials.module.scss";
import { Sites } from "./Sites";
export const Registration = ({ close }: { close: () => void }) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSites, setShowSites] = useState(false);
  const register = async () => {
    if (email === "" || password === "" || confirmPassword === "") {
      setError("Please inter all the fields");
    } else if (password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        await updateProfile(res.user, { displayName: name });
        setError("Registration to all of our sites is Successful");
        setShowSites(true);
      } catch (err: any) {
        setError(err.message);
      }
    }
  };
  return (
    <>
      <div className={Styles.popUp}>
        <p className={Styles.title}>Registration</p>
        <p className={Styles.error}>{error}</p>
        <span className={Styles.closeButton} onClick={close}>
          x
        </span>
        {!showSites ? (
          <div>
            {" "}
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="text"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button onClick={register}>Register</button>
          </div>
        ) : (
          <Sites />
        )}
      </div>
    </>
  );
};
