import { Nav } from "../components/Nav";
import Styles from "../styles/Pages.module.scss";
import { gotTo } from "../modules/goto";
import { useState } from "react";
export const Pages = () => {
  const [showPopUP, setShowPopUP] = useState(false);

  return (
    <>
      <Nav />
      <div className={Styles.popUpContainer}>
        {showPopUP ? (
          <div className={Styles.popUp}>
            <p>Coming Soon</p>
            <span
              className={Styles.closeButton}
              onClick={() => setShowPopUP(false)}
            >
              x
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={Styles.container}>
        <h1 onClick={() => gotTo("https://onco-minds.com/", "distant")}>
          Onco<span>-</span>minds.com
        </h1>
        <h1 onClick={() => gotTo("https://rheum-minds.com/", "distant")}>
          Rheum<span>-</span>minds.com
        </h1>
        <h1 onClick={() => gotTo("https://sleep-minds.com/", "distant")}>
          Sleep<span>-</span>minds.com
        </h1>
        <h1 onClick={() => setShowPopUP(true)}>
          Gastro<span>-</span>minds.com
        </h1>
        <h1 onClick={() => setShowPopUP(true)}>
          Card<span>-</span>minds.com
        </h1>
        <h1 onClick={() => setShowPopUP(true)}>
          Resp<span>-</span>minds.com
        </h1>
        <h1 onClick={() => setShowPopUP(true)}>
          ID<span>-</span>minds.com
        </h1>
        <h1 onClick={() => setShowPopUP(true)}>
          Hep<span>-</span>minds.com
        </h1>
        <h1 onClick={() => setShowPopUP(true)}>
          Derm<span>-</span>minds.com
        </h1>
      </div>
    </>
  );
};
