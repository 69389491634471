import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkAGHBQYpEJ4f8zvfQRUQk5lkKmyksBbs",
  authDomain: "minds-c85e5.firebaseapp.com",
  projectId: "minds-c85e5",
  storageBucket: "minds-c85e5.appspot.com",
  messagingSenderId: "537902541731",
  appId: "1:537902541731:web:a22b72f4bd15c4ada90816",
  measurementId: "G-HL8EXL2EZG",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { auth };
