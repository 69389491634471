import { useState } from "react";
import Styles from "../styles/Main.module.scss";
import OnlineMeeting from "../assets/onlineMeeting.jpeg";
import OncoPage from "../assets/oncoPage.png";
import RheumPage from "../assets/rheumPage.png";
import SleepPage from "../assets/sleepPage.png";
import { gotTo } from "../modules/goto";
import { Nav } from "../components/Nav";
export const Main = () => {
  const [showPopUP, setShowPopUP] = useState(false);
  return (
    <>
      <div id="home"></div>
      <Nav />
      <div>
        <img src={OnlineMeeting} alt="none" className={Styles.meeting} />
      </div>
      <div style={{ position: "relative" }}>
        {showPopUP ? (
          <div className={Styles.popUp}>
            <p>Coming Soon</p>
            <span
              className={Styles.closeButton}
              onClick={() => setShowPopUP(false)}
            >
              x
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={Styles.cardsContainer} id="pages">
        <img
          src={OncoPage}
          alt="none"
          onClick={() => gotTo("https://onco-minds.com/", "distant")}
        />
        <img
          src={RheumPage}
          alt="none"
          onClick={() => gotTo("https://rheum-minds.com/", "distant")}
        />
        <img
          src={SleepPage}
          alt="none"
          onClick={() => gotTo("https://sleep-minds.com/", "distant")}
        />
      </div>
      <div className={Styles.contact}>
        <span onClick={() => gotTo("", "mail")}>Contact Us</span>
      </div>
    </>
  );
};
